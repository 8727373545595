import { CSSProperties } from "react";
import { hexToRGB } from "./hexToRGB";

const getWhiteLabelCssVariables = (mainColor?: string) => {
  if (mainColor === undefined) {
    return {};
  }
  try {
    const { red, green, blue } = hexToRGB(mainColor);
    const rgbColor = `${red}, ${green}, ${blue}`;

    return {
      "--colors-restaurant": mainColor,
      "--colors-restaurant-rgb": rgbColor,
    } as CSSProperties;
  } catch (e) {
    console.debug(e);
    return {};
  }
};

export { getWhiteLabelCssVariables };
